<!-- 抬头 -->
<template>
    <div>
        <NormalDialog
            :visible.sync="dialogVisible"
            :height="40"
            class="three-step"
            top="10vh"
            width="1200px"
        >
            <template #title>
                <div class="title">抬头管理</div>
            </template>
            <template #content>
                <div class="op-line">
                    <el-button
                        class="red"
                        @click="handleAdd()"
                        style="height: 40px"
                    >
                        新增
                    </el-button>
                </div>
                <el-table
                    :data="tableData"
                    style="width: 100%"
                    row-key="id"
                    v-loading="tableLoading"
                >
                    <el-table-column
                        type="index"
                        width="50"
                        label="序号"
                    ></el-table-column>
                    <el-table-column
                        v-for="item in ticketInfoSetting"
                        :key="item.name"
                        :label="item.label"
                        :property="item.name"
                        :width="item.width"
                        :show-overflow-tooltip="true"
                    ></el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        :width="type === 'select' ? 240 : 160"
                    >
                        <template slot-scope="scope">
                            <el-button
                                size="small"
                                type="success"
                                v-if="type === 'select'"
                                @click="handleSelect(scope.row)"
                            >
                                选择
                            </el-button>
                            <el-button
                                size="small"
                                type="primary"
                                @click="handleEdit(scope.row)"
                            >
                                编辑
                            </el-button>
                            <el-button
                                size="small"
                                type="danger"
                                @click="handleRemove(scope.row)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <DialogFooter
                    @confirm="handleClose"
                    cancel-text=""
                    confirm-text="关闭"
                ></DialogFooter>
            </template>
        </NormalDialog>
        <PayMentTicketTitleUpdate
            ref="payMentTicketTitleUpdateRef"
            @refresh="getData"
        ></PayMentTicketTitleUpdate>
    </div>
</template>
<script>
import { getInvoiceTitle, removeCompanyInvoice } from "@/api/pay";
import { ticketInfoSetting } from "@/utils/pay-data-list";
import { apiCallback } from "@/utils";
import DialogFooter from "@/components/layout/DialogFooter";
import NormalDialog from "@/components/layout/NormalDialog";
import PayMentTicketTitleUpdate from "./PayMentTicketTitleUpdate";
export default {
    props: {
        type: {
            type: String,
            default: "look",
        },
    },
    components: {
        DialogFooter,
        NormalDialog,
        PayMentTicketTitleUpdate,
    },
    data() {
        return {
            dialogVisible: false,
            tableData: [],
            ticketInfoSetting,
            tableLoading: false,
        };
    },
    methods: {
        getData() {
            this.tableLoading = true;
            getInvoiceTitle()
                .then((res) => {
                    const data = apiCallback(res);
                    this.tableData = data;
                })
                .finally(() => {
                    this.tableLoading = false;
                });
        },
        init(visible) {
            this.getData();
            this.dialogVisible = visible ?? true;
        },
        handleClose() {
            this.dialogVisible = false;
        },
        handleAdd() {
            this.$refs.payMentTicketTitleUpdateRef.init();
        },
        handleEdit(row) {
            this.$refs.payMentTicketTitleUpdateRef.init(row);
        },
        handleSelect(row) {
            this.$emit("select", row);
            this.dialogVisible = false;
        },
        handleRemove(row) {
            this.$DeleteConfirmDialog({
                msg: `您确定删除该发票抬头吗？`,
                deleteVoid: () => {
                    return removeCompanyInvoice({ id: row.id });
                },
                callBack: () => {
                    this.getData();
                },
                successMsg: "发票抬头删除成功",
            });
        },
    },
};
</script>
<style scoped lang="stylus">
.title {
    font-size: 24px;
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
}

.op-line {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .red {
        background-color: var(--RED-C11C20);
        color: white;
    }
}
</style>
