<template>
    <div class="ticket">
        <el-card style="height: calc(100vh - 160px)">
            <div slot="header" class="header">发票管理</div>
            <div class="toolBar">
                <h4 style="padding: 0 10px">日期</h4>
                <el-date-picker
                    v-model="date"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                ></el-date-picker>
                <h4 style="padding: 0 10px">开票状态</h4>
                <el-select
                    placeholder="请选择开票状态"
                    v-model="ticketStatusType"
                >
                    <el-option
                        v-for="item in ticketStatusTypeSelect"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
                <el-button
                    class="red"
                    @click="handleTable()"
                    style="margin-left: 10px; height: 40px"
                >
                    查询
                </el-button>
                <el-button
                    class="red"
                    style="height: 40px; margin-left: 10px"
                    @click="handleDeclare"
                >
                    申请开票
                </el-button>
                <el-button
                    class="red"
                    style="height: 40px; margin-left: 10px"
                    @click="openTicketInfoDialog()"
                >
                    抬头管理
                </el-button>
                <el-button
                    class="red"
                    style="height: 40px"
                    @click="concatVisible = true"
                >
                    开票联系人
                </el-button>
                <!-- <el-link
                    style="color: var(--RED-C11C20); margin: 0 10px"
                    :underline="false"
                    icon="el-icon-download"
                    @click="handleTable('export')"
                >
                    导出
                </el-link> -->
            </div>
            <el-table
                v-loading="tableLoading"
                :data="tableData"
                height="calc(86vh - 250px)"
                style="width: calc(100% - 40px)"
                row-key="id"
                @selection-change="handleSelectionChange"
            >
                <el-table-column
                    type="selection"
                    :selectable="selectable"
                    width="30"
                    fixed="left"
                ></el-table-column>
                <el-table-column
                    type="index"
                    width="50"
                    label="序号"
                ></el-table-column>
                <el-table-column
                    prop="invoiceStatus"
                    label="开票状态"
                    align="center"
                >
                    <template slot-scope="scope">
                        <el-tag
                            size="small"
                            v-if="handleType[scope.row.invoiceStatus]"
                            :type="handleType[scope.row.invoiceStatus].type"
                            disable-transitions
                        >
                            {{
                                handleType[scope.row.invoiceStatus].name || "-"
                            }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="amount"
                    label="可开票金额"
                    align="center"
                >
                    <template slot-scope="scope">
                        <PayMentMoneyShow
                            :money="Math.abs((scope.row.amount || 0) / payRate)"
                            type="coin"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    label="订单备注"
                    align="center"
                ></el-table-column>
                <el-table-column
                    prop="updateTime"
                    label="消费时间"
                    align="center"
                    width="250"
                >
                    <template slot-scope="scope">
                        {{ scope.row.updateTime }}
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalNum"
                    v-if="totalNum > 0"
                ></el-pagination>
            </div>
        </el-card>

        <!-- 发票抬头 -->
        <PayMentTicketInvoiceTitle
            ref="payMentTicketInvoiceTitleRef"
        ></PayMentTicketInvoiceTitle>

        <!-- 发票申报 -->
        <PayMentTicketDeclare
            ref="payMentTicketDeclareRef"
            @refresh="handleTable"
        />

        <!-- 开票联系人dialog -->
        <el-dialog
            :title="null"
            :visible.sync="concatVisible"
            :close-on-click-modal="false"
        >
            <el-descriptions title="联系人信息">
                <el-descriptions-item label="姓名">叶敏飞</el-descriptions-item>
                <el-descriptions-item label="联系电话">
                    057427836910
                </el-descriptions-item>
                <el-descriptions-item label="邮箱">
                    2501742031@qq.com
                </el-descriptions-item>
                <el-descriptions-item label="QQ">
                    2501742031
                </el-descriptions-item>
                <el-descriptions-item label="工作时间">
                    <el-tag size="small" style="margin-right: 15px">
                        周一至周五
                    </el-tag>
                    <el-tag size="small" style="margin-right: 15px">
                        上午:9:00-11:40
                    </el-tag>
                    <el-tag size="small">下午:13:00-17:40</el-tag>
                </el-descriptions-item>
            </el-descriptions>
        </el-dialog>
    </div>
</template>

<script>
import {
    makeInvoice,
    getCompanyInvoiceRecordDetails,
    exportRecord,
    FEST_URL,
    getInvoiceRecord,
} from "@/api/pay";
import Moment from "moment";
import PayMentDetailBox from "./PayMentDetailBox.vue";
import PayMentTicketInfoDialog from "./PayMentTicketInfoDialog.vue";
import {
    ticketInfoSetting,
    ticketStatus,
    ticketClass,
    newTicketStatus,
    ticketDetailTicketInfo,
    ticketDetailDeliverInfo,
    payRate,
} from "@/utils/pay-data-list";
import { downLoadFile } from "@/utils";
import PayMentTicketInvoiceTitle from "./PayMentTicketInvoiceTitle.vue";
import PayMentTicketDeclare from "./PayMentTicketDeclare.vue";
import PayMentMoneyShow from "./PayMentMoneyShow.vue";

export default {
    components: {
        PayMentDetailBox,
        PayMentTicketInfoDialog,
        PayMentTicketInvoiceTitle,
        PayMentTicketDeclare,
        PayMentMoneyShow,
    },
    data() {
        return {
            accountType: null,
            accountTypeSelect: [
                {
                    value: 0,
                    label: "个人账户",
                },
                {
                    value: 1,
                    label: "企业账户",
                },
                {
                    value: null,
                    label: "全部",
                },
            ],
            ticketStatusType: null,
            ticketStatusTypeSelect: [
                ...newTicketStatus.map((item, index) => ({
                    value: index,
                    label: item.name,
                })),
                {
                    value: null,
                    label: "全部",
                },
            ],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 90,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
            date: "",
            table: [],
            ticketDialogVisible: false,
            ticketLookDialogVisible: false,
            concatVisible: false,
            ticketInfoSetting: ticketInfoSetting,
            companyName: "",
            tableData: [],
            rules: {},
            pageNo: 1,
            pageSize: 20,
            totalNum: 0,
            tableLoading: false,
            ticketInfoDetail: [],
            deliverInfoDetail: [],
            lastParam: "",
            payRate,
            multipleSelection: [],
        };
    },
    computed: {
        handleType() {
            return newTicketStatus;
        },
        handleInvoiceType() {
            return ticketClass;
        },
        filterTicket() {
            return this.filterToValue(this.handleInvoiceType);
        },
        filterType() {
            return this.filterToValue(this.handleType);
        },
    },
    methods: {
        // 获取默认时间
        defaultTime() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            this.date = [start, end];
        },
        // 根据类型筛选
        filterToValue(ele) {
            return ele.map((item, index) => ({
                text: item.name,
                value: index,
            }));
        },

        openTicketInfoDialog() {
            this.$refs.payMentTicketInvoiceTitleRef.init();
        },
        handleLookDetail(id) {
            getCompanyInvoiceRecordDetails({ id }).then(({ data }) => {
                this.ticketLookDialogVisible = true;
                const transData = {
                    ...data.data,
                    ...data.data.companyInvoiceVO,
                };
                this.ticketInfoDetail = ticketDetailTicketInfo.map((item) => ({
                    label: item.label,
                    value:
                        item.prop === "invoiceType"
                            ? ticketClass[transData[item.prop]]?.name ?? ""
                            : transData[item.prop] ?? "",
                    width: item.width ?? "33.3%",
                }));
                this.deliverInfoDetail = ticketDetailDeliverInfo.map(
                    (item) => ({
                        label: item.label,
                        value: transData[item.prop] ?? "",
                        width: item.width ?? "33.3%",
                    }),
                );
            });
        },
        handleCurrentChange(val) {
            this.pageNo = val;
            this.handleTable();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.handleTable();
        },
        handleTable(type = "query") {
            const distanceDate = Moment(this.date[1]) - Moment(this.date[0]);
            const perDayTime = 3600 * 1000 * 24;
            if (distanceDate / perDayTime > 90) {
                this.$message.error("最大时间间隔不能超过90天");
                return;
            }
            const param = {
                startTime:
                    Moment(this.date[0]).format("yyyy-MM-DD") + " 00:00:00",
                endTime:
                    Moment(this.date[1]).format("yyyy-MM-DD") + " 23:59:59",
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                status: this.ticketStatusType,
                accountType: this.accountType,
            };
            this.tableLoading = true;
            type === "query" &&
                getInvoiceRecord(param)
                    .then(({ data }) => {
                        this.lastParam = param;
                        if (data.data) {
                            this.tableData = data.data.list;
                            this.totalNum = data.data.total;
                        } else {
                            this.tableData = [];
                            this.totalNum = 0;
                        }
                    })
                    .catch(() => {
                        this.tableData = [];
                        this.totalNum = 0;
                    })
                    .finally(() => {
                        this.tableLoading = false;
                    });
            type === "export" &&
                exportRecord(this.lastParam)
                    .then(({ data }) => {
                        downLoadFile(FEST_URL + data.data);
                    })
                    .finally(() => {
                        this.tableLoading = false;
                    });
        },
        // 选中
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 申报
        handleDeclare() {
            // 拿到选中的值
            // console.log(this.multipleSelection);
            if (this.multipleSelection.length === 0) {
                this.$message.error("请选择需要申报的消费记录");
                return;
            }
            this.$refs.payMentTicketDeclareRef.init(this.multipleSelection);
            // makeInvoice({ id: id, type: 1 }).then(() => {
            //     this.$message.success("申报成功");
            //     this.handleTable();
            // });
        },

        // 是否可以选择
        selectable: function (row) {
            return row.invoiceStatus == 0;
        },
    },
    mounted() {
        this.defaultTime();
        this.handleTable();
    },
};
</script>
<style scoped lang="stylus">
/deep/ .el-card__header {
    background-image: linear-gradient(
        var(--RED-C11C20),
        var(--RED-79031D),
        var(--RED-68020F)
    );
}
.red {
    background-color: var(--RED-C11C20);
    color: white;
}
.ticket {
    min-width: 800px;
}
.header {
    color: white;
    font-size: 18px;
    font-weight: 700;
}

.pagination {
    display: flex;
    justify-content: flex-end;
    margin: 10px 20px 100px 0px;
}

.toolBar {
    display: flex;
    flex-wrap:wrap;
    row-gap: 10px;
}
</style>
