<!-- 抬头新增或者编辑 -->
<template>
    <div>
        <!-- 设置发票信息dialog -->
        <NormalDialog
            :visible.sync="ticketDialogVisible"
            center
            width="700px"
            top="10px"
            :height="40"
            class="three-step"
        >
            <template #title>
                <div class="setting-title">抬头信息</div>
            </template>
            <template #content>
                <el-form
                    :model="form"
                    label-width="200px"
                    :rules="rules"
                    ref="form"
                >
                    <el-form-item label="开票类别">
                        <el-radio-group v-model="type">
                            <el-radio :label="0">
                                增值税普通发票（电子发票）
                            </el-radio>
                            <el-radio :label="1">
                                增值税专用发票（电子发票）
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="企业名称">
                        <el-input
                            placeholder="企业名称"
                            v-model="form.companyName"
                        ></el-input>
                    </el-form-item>
                    <el-form-item
                        v-for="item in ticketInfoSetting"
                        :key="item.label"
                        :label="item.label"
                        :prop="item.name"
                        v-if="!isNaN(item.type) ? type === item.type : true"
                    >
                        <el-input
                            :placeholder="item.label"
                            v-model="form[item.name]"
                        ></el-input>
                    </el-form-item>
                </el-form>
                <DialogFooter
                    @confirm="handleSaveSetting"
                    @cancel="handleCalcelSetting"
                ></DialogFooter>
            </template>
        </NormalDialog>
    </div>
</template>
<script>
import {
    addCompanyInvoice,
    getCompanyInvoiceRecordDetails,
    exportRecord,
    FEST_URL,
} from "@/api/pay";
import { ticketInfoSetting } from "@/utils/pay-data-list";
import DialogFooter from "@/components/layout/DialogFooter";
import NormalDialog from "@/components/layout/NormalDialog";
export default {
    data() {
        return {
            ticketDialogVisible: false,
            type: 1,
            ticketInfoSetting,
            rules: {},
            form: {
                ...Object.fromEntries(
                    ticketInfoSetting.map((item) => [item.name, ""]),
                ),
            },
        };
    },
    components: {
        DialogFooter,
        NormalDialog,
    },
    methods: {
        // 发票信息设置保存
        handleSaveSetting() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    addCompanyInvoice({
                        ...this.form,
                        type: this.type,
                        companyId: this.$store.state.user.cid || null,
                    }).then(() => {
                        this.ticketDialogVisible = false;
                        this.lastForm = this.form;
                        this.$message.success("发票信息保存成功");
                        this.$emit("refresh");
                    });
                } else {
                    return false;
                }
            });
        },
        // 前往企业认证页面
        goCompanyConfirm() {
            this.$router.push("/user/ent-company");
        },
        // 发票信息设置取消
        handleCalcelSetting() {
            this.ticketDialogVisible = false;
        },
        init(row) {
            if (row) {
                this.form = {
                    ...row,
                };
            } else {
                this.form = {
                    ...Object.fromEntries(
                        ticketInfoSetting.map((item) => [item.name, ""]),
                    ),
                };
            }
            this.ticketDialogVisible = true;
        },
    },
};
</script>
<style scoped lang="stylus">
.setting-title {
    font-size: 24px;
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
}
</style>
