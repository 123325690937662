<!-- 发票申报 -->
<template>
    <NormalDialog
        :visible.sync="visible"
        :height="40"
        class="three-step"
        top="10vh"
        width="1200px"
    >
        <template #title>
            <div class="title">发票申报</div>
        </template>
        <template #content>
            <div class="tips">
                本次申报发票共计
                <span>{{ historyTickets.length }}条</span>
                消费记录，合计金额
                <span>{{ totalMoney }}元</span>
            </div>
            <div class="bar-title">
                选择开票抬头：
                <span v-if="currentRow">
                    纳税号:{{ currentRow.taxIdNumber }}
                </span>
            </div>
            <el-table
                :data="tableData"
                style="width: 100%"
                row-key="id"
                highlight-current-row
                @current-change="handleCurrentChange"
                ref="tableRef"
            >
                <el-table-column
                    type="index"
                    width="50"
                    label="序号"
                ></el-table-column>
                <el-table-column
                    v-for="item in ticketInfoSetting"
                    :key="item.name"
                    :label="item.label"
                    :property="item.name"
                    :width="item.width"
                    :show-overflow-tooltip="true"
                ></el-table-column>
            </el-table>
            <DialogFooter
                @confirm="handleConfirm"
                cancel-text="取消"
                @cancel="visible = false"
                confirm-text="申请"
            ></DialogFooter>
        </template>
    </NormalDialog>
</template>
<script>
import DialogFooter from "@/components/layout/DialogFooter";
import NormalDialog from "@/components/layout/NormalDialog";
import { getInvoiceTitle, makeInvoiceNew } from "@/api/pay";
import { payRate, ticketInfoSetting } from "@/utils/pay-data-list";
import { math } from "@/utils/math";
import { apiCallback } from "@/utils";

export default {
    data() {
        return {
            visible: false,
            payRate,
            historyTickets: [],
            totalMoney: 0,
            tableData: [],
            ticketInfoSetting,
            currentRow: null,
        };
    },
    components: {
        DialogFooter,
        NormalDialog,
    },
    methods: {
        //         * @param {int32} amnount 订单金额
        //  * @param {int32} companyId 公司id
        //  * @param {int32} invoiceId 发票信息id
        //  * @param {String} recordId 订单id,多个用逗号隔开
        // //  * @param {int32} invoiceType 发票类型
        handleConfirm() {
            const param = {
                amnount: this.totalMoney * payRate,
                companyId: this.$store.state.user.cid || null,
                invoiceId: this.currentRow.id,
                recordId: this.historyTickets.map((item) => item.id).join(","),
                invoiceType:this.currentRow.type,
            };
            makeInvoiceNew(param).then((res) => {
                this.visible = false;
                this.$message.success("发票已提交申请,请耐心等待...");
                this.$emit("refresh");
            });
        },
        handleCancel() {
            this.visible = false;
        },
        init(data) {
            this.historyTickets = data;
            let totalMoney = 0;
            this.getData();
            data.forEach((item) => {
                totalMoney = math.add(totalMoney, Math.abs(item.amount || 0));
            });
            this.totalMoney = totalMoney / payRate;
            this.visible = true;
        },
        getData() {
            getInvoiceTitle().then((res) => {
                const data = apiCallback(res);
                this.tableData = data;
                // 只有一条,默认选中第一条
                if (data.length === 1) {
                    this.$refs.tableRef.setCurrentRow(data[0]);
                }
            });
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        },
    },
};
</script>

<style scoped lang="stylus">
.title {
    font-size: 24px;
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
}

.tips{
    font-size: 16px;
    margin-bottom: 20px;
    span{
        color:red;
    }
}

.bar-title{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}
</style>
